#links-container {
  display: flex;
}

tr {
  min-height: 100vw;
}

td > div {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 10vw;
  max-width: 10vw;
  margin: 1vw;
}

td > div:hover {
  box-shadow: 0 0 20px #f38630;
  -webkit-filter: blur(1px); /* Safari 6.0 - 9.0 */
  cursor: pointer;
  filter: none;
}

a img {
  max-height: 3vw;
  max-width: 3vw;
  filter: drop-shadow();
}

@media screen and (max-width: 1366px) {
  a img {
    max-height: 10vw;
    max-width: 10vw;
  }
  table {
    margin-top: 6vh;
  }
}
