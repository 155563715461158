.App {
  display: flex;
  flex-direction: row;
  background-image: url("./images/bg5.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

@media screen and (max-width: 1366px) {
  .App {
    flex-direction: column;
  }
  #left-half {
    height: 40vh !important;
    width: 100% !important;
  }
  #right-half {
    height: 60vh !important;
    width: 100% !important;
    justify-content: unset !important;
    align-items: center !important;
  }
  #para {
    display: none;
  }

  #mytitle {
    font-size: 5vw !important;
  }
  #name {
    font-size: 8vw !important;
  }
}

#mytitle {
  font-size: 2vw;
  font-weight: lighter;
  color: #a7dbd8;
}

#name {
  font-size: 5vw;
  font-weight: bold;
  color: #69d2e7;
}

.bg-image {
  height: 100%;
  width: 100%;
}
#left-half {
  width: 50vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

#sun1 {
  left: 85vw !important;
  top: 2vw !important;
}

#note1_2 {
  left: 50vw !important;
  top: 70vh !important;
  z-index: 4;
  height: 5vh;
}

#note2_1 {
  left: 5vw !important;
  top: 70vh !important;
  z-index: 4;
  height: 5vh;
}

#note3_1 {
  left: 10vw !important;
  top: 8vh !important;
  z-index: 4;
  height: 5vh;
}

#note3_2 {
  left: 15vw !important;
  top: 5vh !important;
  z-index: 4;
  height: 5vh;
}

#left-half > div:nth-child(2) {
  transform: translateX(-22vmin) translateY(-23vmin);
}

#right-half {
  width: 50vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  word-wrap: break-word;
  flex-direction: column;
}

#para {
  position: absolute;
}

#para > div > img {
  height: 10vw;
  width: 10vw;
}
